<template>
  <div v-if="!isMobile">
    <div
      class="items-center window-height justify-center row"
      v-if="isRedirecting"
    >
      <q-circular-progress
        indeterminate
        size="75px"
        :thickness="0.1"
        color="primary"
        class="q-mb-md"
      />
    </div>
    <div v-else class="invitation-view">
      <div class="flex-direction window-height page-layout">
        <div class="left-side-view text-white">
          <img class="image" src="/static/images/create-company/step1.jpg" />
        </div>
        <div class="left-side-view text-white">
          <!-- <img
            @click="redirectToSite"
            style="margin-top: 80px"
            alt="logo"
            class="cursor-pointer"
            src="/static/images/signin/heycollab_logo_white.svg"
          />
          <div style="margin-top: 110px">
            <div class="big-text">
              <div>Works.</div>
              <div>In harmony.</div>
            </div>
            <div class="small-text q-pr-xl q-mt-md app-text-normal">
              A new project coordination tool for teams
            </div>
          </div>
          <div class="row bottom-content absolute app-text-normal">
            <div @click="openAgreementDialog('policy')" class="q-mr-md">
              Privacy Policy
            </div>
            <div @click="openAgreementDialog('terms')" class="app-text-normal">
              Terms of use
            </div>
          </div> -->
        </div>
        <div class="right-side-view col-grow">
          <div class="column flex-no-wrap invitation-container q-pa-md">
            <div class="row justify-between items-center">
              <div class="flex">
                <img
                  @click="redirectToSite"
                  alt="logo"
                  class="cursor-pointer"
                  src="/static/images/logo/logo.svg"
                />
              </div>
            <div class="top-right-text app-text-normal" style="font-weight: 500;">
              <q-btn
                label="Sign up"
                color="primary"
                size="md"
                class="q-mr-sm"
                no-caps
                @click="$router.push({ name: 'SignupView' })"
              />
              <q-btn
                outline
                label="Sign in"
                color="primary"
                size="md"
                no-caps
                @click="$router.push({ name: 'SigninView' })"
              />
            </div>
          </div>
            <div
              class="col-grow row justify-center items-center invitation-form-wrapper"
            >
              <form
                class="invitation-form"
                v-on:submit.prevent="isDisabled ? null : submitForm()"
              >
                <div class="step-title app-text-normal">
                  Join {{ this.$route.params.company }}
                </div>
                <div class="q-mt-md">
                  <div class="input-label app-text-normal">Email</div>
                  <q-input
                    type="text"
                    v-model="email"
                    @input="$v.email.$touch()"
                    @blur="checkEmailAvaibility()"
                    :error="fieldErrors('email').length > 0"
                    :disable="invite"
                    dense
                    outlined
                    bottom-slots
                    no-error-icon
                  >
                    <template v-slot:error>
                      <div class="text-negative">
                        {{
                          fieldErrors("email").length > 0
                            ? fieldErrors("email")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                  <div class="text-center">
                    <q-circular-progress
                      indeterminate
                      size="30px"
                      :thickness="0.2"
                      color="pink"
                      class="q-mb-md"
                      v-if="loader.emailAvaibility"
                    />
                  </div>
                  <div
                    class="text-negative q-mt-sm"
                    style="font-size: 11px"
                    v-if="errorMessage.emailAvaibility"
                  >
                    {{ errorMessage.emailAvaibility }}
                  </div>
                </div>
                <div class="row flex-wrap q-mt-sm">
                  <div class="spacing-right col-grow q-mb-sm">
                    <div class="input-label">First Name</div>
                    <q-input
                      type="text"
                      v-model="first"
                      @input="$v.first.$touch()"
                      @blur="$v.first.$touch()"
                      :error="fieldErrors('first').length > 0"
                      dense
                      outlined
                      bottom-slots
                      no-error-icon
                    >
                      <template v-slot:error>
                        <div class="text-negative">
                          {{
                            fieldErrors("first").length > 0
                              ? fieldErrors("first")[0]
                              : ""
                          }}
                        </div>
                      </template>
                    </q-input>
                  </div>
                  <div class="col-grow">
                    <div class="input-label">Last Name</div>
                    <q-input
                      type="text"
                      v-model="last"
                      @input="$v.last.$touch()"
                      @blur="$v.last.$touch()"
                      :error="fieldErrors('last').length > 0"
                      outlined
                      dense
                      bottom-slots
                      no-error-icon
                    >
                      <template v-slot:error>
                        <div class="text-negative">
                          {{
                            fieldErrors("last").length > 0
                              ? fieldErrors("last")[0]
                              : ""
                          }}
                        </div>
                      </template>
                    </q-input>
                  </div>
                </div>
                <div class="input-info-btn-popover q-mt-sm">
                  <div class="input-label">Username</div>
                  <q-input
                    type="text"
                    v-model="username"
                    @input="handleUsernameInput"
                    @blur="checkUsernameAvaibility"
                    :error="fieldErrors('username').length > 0"
                    :disabled="restore !== null"
                    dense
                    outlined
                    bottom-slots
                    no-error-icon
                    :hint="
                      username && !fieldErrors('username').length
                        ? 'Use only lowercase, no spaces, letters, numbers, hyphens & underscores'
                        : ''
                    "
                  >
                    <template v-slot:error>
                      <div class="text" style="color: #5e7191" v-if="!username">
                        Use only lowercase, no spaces, letters, numbers, hyphens
                        & underscores
                      </div>
                      <div class="text-negative q-mb-xs">
                        {{
                          fieldErrors("username").length > 0
                            ? fieldErrors("username")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                  <div class="text-center">
                    <q-circular-progress
                      indeterminate
                      size="30px"
                      :thickness="0.2"
                      color="pink"
                      class="q-mb-md"
                      v-if="loader.usernameAvaibility"
                    />
                  </div>
                  <div
                    class="text-negative q-mt-sm"
                    style="font-size: 11px"
                    v-if="errorMessage.usernameAvaibility"
                  >
                    {{ errorMessage.usernameAvaibility }}
                  </div>
                  <!-- <v-popover
                    autoHide
                    :handleResize="true"
                    offset="10px"
                    class="no-height"
                    placement="left-start"
                    :open="popover.username"
                    popoverClass="vb-info-popover"
                  >
                    <q-btn
                      flat
                      size="md"
                      round
                      :icon="$icons.matInfo"
                      class="q-mt-sm"
                      style="color:#D2E1F1"
                      @mouseenter="popover.username = true"
                      @mouseleave="popover.username = false"
                    />
                    <template slot="popover">
                      <div class="vb-popover-information">
                        <q-icon
                          :name="$icons.matInfo"
                          color="white"
                          size="md"
                          class="absolute"
                        />
                        <div class="info-container">
                          <p>Usernames must be all lowercase, with no spaces.</p>
                          <p>
                            They can only contain
                            <span class="demi-bold-pink">
                              letters, numbers, periods,
                              <br />hyphens, and underscores.
                            </span>
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-popover> -->
                </div>
                <!-- <div class="q-mt-sm">
                  <div class="input-label">Password</div>
                  <password
                    :email="email"
                    v-model="password"
                    :badge="false"
                    :isDense="true"
                    hint="Password must be 8 characters or longer."
                    required
                  ></password>
                </div>
                <div class="q-mt-sm">
                  <div class="input-label">Confirm Password</div>
                  <q-input
                    type="password"
                    v-model="repeatPassword"
                    @input="$v.repeatPassword.$touch()"
                    @blur="$v.repeatPassword.$touch()"
                    :error="fieldErrors('repeatPassword').length > 0"
                    dense
                    outlined
                    bottom-slots
                    no-error-icon
                  >
                    <template v-slot:error>
                      <div class="text-negative">
                        {{
                          fieldErrors("repeatPassword").length > 0
                            ? fieldErrors("repeatPassword")[0]
                            : ""
                        }}
                      </div>
                    </template>
                  </q-input>
                </div> -->
                <div class="row q-mt-md">
                  <div
                    class="spacing-right col-grow input-info-btn-popover q-mb-sm"
                  >
                    <div class="input-label">Password</div>
                    <q-input
                      ref="input"
                      type="password"
                      v-model="password"
                      v-bind:value="password"
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                      :error="fieldErrors('password').length > 0"
                      outlined
                      dense
                      bottom-slots
                      no-error-icon
                      :hint="
                        password && !fieldErrors('password').length
                          ? 'Password must be 8 characters or longer.'
                          : ''
                      "
                    >
                      <template v-slot:error>
                        <div
                          class="text"
                          style="color: #5e7191"
                          v-if="!password"
                        >
                          Password must be 8 characters or longer.
                        </div>
                        <div class="text-negative" v-else>
                          {{
                            fieldErrors("password").length > 0
                              ? fieldErrors("password")[0]
                              : ""
                          }}
                        </div>
                      </template>
                    </q-input>
                    <!-- <v-popover
              autoHide
              :handleResize="true"
              offset="10px"
              class="no-height"
              placement="bottom-start"
              :open="popover.password"
              popoverClass="vb-info-popover"
            >
              <q-btn
                flat
                size="md"
                round
                style="color:#D2E1F1"
                :icon="$icons.matInfo"
                class="q-mt-sm"
                @mouseenter="popover.password = true"
                @mouseleave="popover.password = false"
              />
              <template slot="popover">
                <div class="vb-popover-information">
                  <q-icon
                    :name="$icons.matInfo"
                    color="white"
                    size="md"
                    class="absolute"
                  />
                  <div class="info-container">
                    <p>
                      Password must be at least 8 characters
                      <br />
                      <span class="demi-bold-pink">Recommended to include:</span>
                    </p>
                    <ul class="password-ul">
                      <li class="demi-bold password-strength-li">
                        1+ uppercase letters
                        <span class="demi-bold-pink">(A, B, C)</span>
                      </li>
                      <li class="demi-bold password-strength-li">
                        1+ special characters
                        <span class="demi-bold-pink">(&,!,@)</span>
                      </li>
                      <li class="demi-bold password-strength-li">
                        1+ numeric characters
                        <span class="demi-bold-pink">(1,2,3)</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </v-popover> -->
                  </div>
                  <div class="col-grow input-info-btn-popover">
                    <div class="input-label">Confirm Password</div>
                    <q-input
                      type="password"
                      v-model="repeatPassword"
                      @input="$v.repeatPassword.$touch()"
                      @blur="$v.repeatPassword.$touch()"
                      :error="fieldErrors('repeatPassword').length > 0"
                      outlined
                      dense
                      bottom-slots
                      no-error-icon
                    >
                      <template v-slot:error>
                        <div class="text-negative">
                          {{
                            fieldErrors("repeatPassword").length > 0
                              ? fieldErrors("repeatPassword")[0]
                              : ""
                          }}
                        </div>
                      </template>
                    </q-input>
                    <!-- <v-popover
              autoHide
              :handleResize="true"
              offset="10px"
              class="no-height"
              placement="bottom-start"
              :open="popover.confPassword"
              popoverClass="vb-info-popover"
            >
              <q-btn
                flat
                size="md"
                round
                style="color:#D2E1F1"
                :icon="$icons.matInfo"
                class="q-mt-sm"
                @mouseenter="popover.confPassword = true"
                @mouseleave="popover.confPassword = false"
              />
              <template slot="popover">
                <div class="vb-popover-information">
                  <q-icon
                    :name="$icons.matInfo"
                    color="white"
                    size="md"
                    class="absolute"
                  />
                  <div class="info-container">
                    <p>
                      Password must be at least 8 characters
                      <br />
                      <span class="demi-bold-pink">Recommended to include:</span>
                    </p>
                    <ul class="password-ul">
                      <li class="demi-bold password-strength-li">
                        1+ uppercase letters
                        <span class="demi-bold-pink">(A, B, C)</span>
                      </li>
                      <li class="demi-bold password-strength-li">
                        1+ special characters
                        <span class="demi-bold-pink">(&,!,@)</span>
                      </li>
                      <li class="demi-bold password-strength-li">
                        1+ numeric characters
                        <span class="demi-bold-pink">(1,2,3)</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </v-popover> -->
                  </div>
                </div>
                <div
                  class="row justify-center items-center q-mt-sm"
                  v-show="!$v.email.$invalid && !invite"
                >
                  <masked-input
                    mask="111-11"
                    placeholder="000-00"
                    @input="resetVerificationFlag(arguments[1])"
                    class="masked-box"
                    type="tel"
                  />
                  <timmer
                    :seconds="60"
                    :onlyseconds="true"
                    @timeover="startTimer = false"
                    v-show="startTimer"
                  >
                    <span class="q-mr-xs" style="font-weight: 600"
                      >Resend Verification Code in</span
                    >
                    <span slot="footer">seconds.</span>
                  </timmer>
                  <q-btn
                    flat
                    dense
                    size="md"
                    label="Get Verification Code"
                    class="q-ml-sm"
                    color="primary"
                    no-caps
                    @click.native.stop="sendVerificationCode"
                    v-show="!startTimer"
                  />
                </div>
                <div class="cb-with-span q-mt-md">
                  <q-checkbox size="md" v-model="privacyCheck" />
                  <span class="signup-label-text q-ml-md q-mt-xs">
                    I agree to heycollab's
                    <span
                      class="linkColor--text cursor-pointer"
                      @click="openAgreementDialog('policy')"
                      >Privacy Policy</span
                    >
                  </span>
                </div>
                <div class="cb-with-span">
                  <q-checkbox size="md" v-model="termsCheck" />
                  <span class="signup-label-text q-ml-md q-mt-xs">
                    I am 18 years of age and have read and agree to heycollab's
                    <span
                      class="linkColor--text cursor-pointer"
                      @click="openAgreementDialog('terms')"
                      >Terms of Use</span
                    >
                  </span>
                </div>
                <div v-if="serverValidation" class="text-negative text-center">
                  {{ serverValidation }}
                </div>
                <!-- <div class="text-negative text-center" v-if="isAvailabityError">
                  {{ availabityErrorMessage }}
                </div> -->
                <div
                  v-if="errorMessage.isverified"
                  class="text-negative text-center"
                >
                  {{ errorMessage.isverified }}
                </div>
                <q-btn
                  type="submit"
                  no-caps
                  text-color="white"
                  padding="0"
                  :label="restore === null ? 'Sign Up' : 'Restore Account'"
                  class="signin-btn-class"
                  :loading="loader.submit"
                  :disabled="isDisabled"
                />
              </form>
              <!-- <p class="text-negative" v-if="invalidPassword">
                Password security is very low
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <terms-condition-dialog
        v-model="termsConditionDialog"
        v-if="termsConditionDialog"
        :defaultView="defaultView"
      />
    </div>
  </div>
  <div v-else>
    <mobile-singin-view> </mobile-singin-view>
  </div>
</template>

<script>
import { login, getUser, getWorkspaces } from "@/services/auth";

//import { VPopover } from "v-tooltip";

import has from "lodash/has";
import {
  email,
  required,
  minLength,
  sameAs,
  requiredIf,
  alpha,
  maxLength,
} from "vuelidate/lib/validators";
import { uniqueUser } from "@/utils/validators";

import errorHandler from "@/utils/ErrorHandler";

import validationMixin from "@/mixins/validationMixin";
import VerificationCodeMixin from "@/mixins/VerificationCodeMixin";

import TermsConditionDialog from "@/components/Dialogs/TermsConditionDialog";
// import Password from "@/components/PasswordStrength";
import MobileSinginView from "@/views/MobileSigninView";

import {
  CheckInvitationLinkMutation,
  CheckCompanyPublicLinkMutation,
  CheckEmailAvailabilityMutation,
  CheckUsernameMutation,
  GetUserByEmailMutation,
  MemberRestoreMutation,
  MemberSignupMutation,
  UserActionLogsMutation,
  Workspaces2Query,
  CompanyQuery,
  CheckCompanyStatusMutation,
} from "@/gql";

export default {
  name: "InvitationView",
  mixins: [validationMixin, VerificationCodeMixin],
  components: {
    TermsConditionDialog,
    //VPopover,
    // Password,
    MobileSinginView,
  },
  validations: {
    email: { required, email },
    first: {
      required,
      alpha,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    last: {
      required,
      alpha,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    code: requiredIf(function () {
      return !this.invite;
    }),
    username: {
      required,
      uniqueUser,
      minLength: minLength(4),
    },
    password: {
      required,
      minLength: minLength(8),
      //validPassword,
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
    agreeToPolicy: { required },
  },
  validationMessages: {
    first: {
      required: "message.validation.userprofile.first.required",
      alpha: "message.validation.userprofile.first.firstname",
      maxLength: "message.validation.userprofile.first.max",
      minLength: "message.validation.userprofile.first.min",
    },
    last: {
      required: "message.validation.userprofile.last.required",
      alpha: "message.validation.userprofile.last.lastname",
      maxLength: "message.validation.userprofile.last.max",
      minLength: "message.validation.userprofile.last.min",
    },
    email: {
      required: "message.validation.email.required",
      email: "message.validation.email.email",
    },
    username: {
      required: "message.validation.username.required",
      uniqueUser: "message.validation.username.uniqueUser",
      minLength: "message.validation.username.min",
    },
    password: {
      required: "message.validation.password.required",
      minLength: "message.validation.password.min",
      //validPassword: "message.validation.password.validPassword",
    },
    repeatPassword: {
      sameAsPassword: "message.validation.password.confirm",
    },
    code: { required: "message.validation.verificationCode.required" },
    agreeToPolicy: {
      required: "message.validation.company.agreeToPolicy.required",
    },
  },
  created() {
    const { secret, type } = this.$route.query;
    this.invite = type === "private";
    this.secret = secret;
    this.company_uri = this.$route.params.company;

    if (this.invite === true) {
      this.checkInvitationLink();
    } else {
      this.checkPublicInvitationLink();
    }
  },
  data() {
    return {
      termsConditionDialog: false,
      defaultView: null,
      invite: false,
      secret: null,
      company_uri: null,
      first: null,
      last: null,
      email: null,
      username: null,
      password: null,
      repeatPassword: null,
      startTimer: false,
      privacyCheck: false,
      termsCheck: false,
      isRedirecting: false,
      loader: {
        usernameAvaibility: false,
        emailAvaibility: false,
        submit: null,
      },
      errorMessage: {
        usernameAvaibility: null,
        emailAvaibility: null,
        isverified: null,
      },
      errorFlag: {
        usernameAvaibility: false,
        emailAvaibility: false,
        isverified: false,
      },
      popover: {
        username: false,
        password: false,
        confPassword: false,
      },
      serverValidation: null,
      restore: null,
      // invalidPassword: null,
      company: null,
      isMobile: false,
    };
  },
  methods: {
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    redirectToSite() {
      window.open("https://heycollab.com/");
    },
    openAgreementDialog(view) {
      this.termsConditionDialog = true;
      this.defaultView = view;
    },
    async checkInvitationLink() {
      let variables = {
        company_uri: this.company_uri,
        secret: this.secret,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CheckInvitationLinkMutation,
          variables,
        });

        const invitation = response.data.checkInvitationLink;

        if (invitation === null) {
          this.$router.push({
            name: "SigninView",
          });
        } else {
          this.email = invitation.email;
          await this.getUserByEmail();
        }
      } catch (error) {
        this.snackBarHandler(
          errorHandler.getErrorMessage(
            error,
            this,
            this.$t("message.message.CompanyNotFoundError")
          )
        );
        setTimeout(() => {
          this.$router.push({
            name: "SigninView",
          });
        }, 5000);
      }
    },
    async checkPublicInvitationLink() {
      let variables = {
        company_uri: this.company_uri,
        secret: this.secret,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CheckCompanyPublicLinkMutation,
          variables,
        });

        const checkCompanyPublicLink = response.data.checkCompanyPublicLink;

        if (!checkCompanyPublicLink) {
          this.$router.push({
            name: "SigninView",
          });
        }
      } catch (error) {
        this.snackBarHandler(
          errorHandler.getErrorMessage(
            error,
            this,
            this.$t("message.message.CompanyNotFoundError")
          )
        );
        setTimeout(() => {
          this.$router.push({
            name: "SigninView",
          });
        }, 5000);
      }
    },
    async checkEmailAvaibility() {
      this.loader.emailAvaibility = true;
      let variables = {
        company_uri: this.company_uri,
        email: this.email,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CheckEmailAvailabilityMutation,
          variables,
        });

        const result = response.data.checkEmailAvaibility;

        if (result === false) {
          this.errorMessage.emailAvaibility = this.$t(
            "message.validation.email.alreadyExist"
          );
          this.errorFlag.emailAvaibility = true;
        } else {
          this.errorFlag.emailAvaibility = false;
          this.errorMessage.emailAvaibility = null;
        }
        this.loader.emailAvaibility = false;
      } catch (error) {
        this.loader.emailAvaibility = false;
      }
    },
    handleUsernameInput() {
      this.$v.username.$touch();
      this.username = this.username.toLowerCase();
    },
    async checkUsernameAvaibility() {
      this.loader.usernameAvaibility = true;

      this.errorMessage.usernameAvaibility = null;
      this.errorFlag.usernameAvaibility = false;

      let variables = {
        company_uri: this.company_uri,
        username: this.username,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CheckUsernameMutation,
          variables,
        });

        const result = response.data.checkUsernameAvaibility;
        if (result === false) {
          this.errorMessage.usernameAvaibility = this.$t(
            "message.validation.username.alreadyExist"
          );
          this.errorFlag.usernameAvaibility = true;
        } else {
          this.errorMessage.usernameAvaibility = null;
          this.errorFlag.usernameAvaibility = false;
        }
        this.loader.usernameAvaibility = false;
      } catch (error) {
        this.loader.usernameAvaibility = false;
      }
    },
    resetVerificationFlag(arg) {
      this.code = arg;
      this.errorMessage.isverified = null;
      this.errorFlag.isverified = false;
    },
    async getUserByEmail() {
      let variables = {
        company_uri: this.company_uri,
        email: this.email,
      };

      const response = await this.$api.mutate({
        mutation: GetUserByEmailMutation,
        variables,
      });

      const user = response.data.getUserByEmail;

      if (user === null) {
        this.restore = null;
      } else {
        this.restore = user;
        this.first = user.first;
        this.last = user.last;
        this.username = user.username;
      }
    },
    async verifyVerificationCode() {
      this.invalidCodeMessage = null;

      let variables = {
        email: this.email,
        code: this.code,
      };

      try {
        const response = await this.checkVerificationMutation(variables);

        const isCorrectVerificationCode = response.data.checkVerificationCode;
        if (isCorrectVerificationCode === false) {
          this.errorMessage.isverified = this.$t(
            "message.InvalidVerificationCodeError"
          );
          this.errorFlag.isverified = true;
        } else {
          this.errorMessage.isverified = null;
          this.errorFlag.isverified = false;
        }
        this.loader.isverified = false;
      } catch (error) {
        this.errorMessage.isverified = this.$t(
          "message.InvalidVerificationCodeError"
        );
        this.loader.isverified = false;
      }
    },
    submitForm() {
      if (this.restore === null) {
        this.createUser();
      } else {
        this.restoreMemberAccount();
      }
    },
    async createUser() {
      this.loader.submit = true;

      await this.checkUsernameAvaibility();
      await this.checkEmailAvaibility();

      if (!this.invite) {
        await this.verifyVerificationCode();
      }

      const checkVerifcationProcess = this.invite
        ? true
        : !this.errorFlag.isverified;

      if (
        !this.errorFlag.emailAvaibility &&
        !this.errorFlag.usernameAvaibility &&
        checkVerifcationProcess
      ) {
        let variables = {
          invite: this.invite,
          first: this.first,
          last: this.last,
          email: this.email,
          username: this.username,
          company_uri: this.company_uri,
          secret: this.secret,
          password: this.password,
          allow_occational_mail: true,
          toc: this.agreeToPolicy,
          code: this.code === "" ? 10000 : this.code,
        };
        try {
          const response = await this.$api.mutate({
            mutation: MemberSignupMutation,
            variables,
          });
          if (response && !response.errors) {
            this.loader.submit = false;
            this.isRedirecting = true;
            this.snackBarHandler("You've successfully signed up.");
            // setTimeout(() => {
            //   this.isRedirecting = false;
            //   this.$router.push({
            //     name: "SigninView",
            //   });
            // }, 5000);
            this.$mixpanelEvent("invitation-accept", response);
            if (!this.checkMobile()) {
              this.singIn();
              this.isMobile = false;
            } else {
              this.isMobile = true;
            }
          } else {
            this.isMobile = false;
            this.loader.submit = false;
            let errorMessage = "Something went wrong";
            if (
              response.errors &&
              response.errors[0].message === "InvitationLimitExided"
            ) {
              errorMessage = "Invitaion limit exceeded";
            }
            this.$q.notify({
              color: "negative",
              position: "top-right",
              message: errorMessage,
              timeout: 2500,
              icon: this.$icons.matAnnouncement,
              actions: [{ icon: this.$icons.matClose, color: "white" }],
            });
          }
        } catch (error) {
          if (error.graphQLErrors[0].message === "CompanyNotFoundError") {
            this.serverValidation = errorHandler.getErrorMessage(
              error,
              this,
              "Company doesn't exist"
            );
          } else if (error.graphQLErrors[0].message === "InvitationNotFound") {
            this.serverValidation = errorHandler.getErrorMessage(
              error,
              this,
              "Invalid Invitation"
            );
          } else if (
            error.graphQLErrors[0].message === "InvitationLimitExided"
          ) {
            this.serverValidation = errorHandler.getErrorMessage(
              error,
              this,
              "Invitaion limit exceeded"
            );
          } /*else if (error.graphQLErrors[0].message === "PasswordInvalid") {
            this.invalidPassword = true;
          }*/ else {
            this.serverValidation = errorHandler.getErrorMessage(
              error,
              this,
              "Something went wrong"
            );
          }
          this.loader.submit = false;
        }
      } else {
        this.loader.submit = false;
      }
    },
    async restoreMemberAccount() {
      this.loader.submit = true;

      if (!this.invite) {
        await this.verifyVerificationCode();
      }

      const checkVerifcationProcess = this.invite
        ? true
        : !this.errorFlag.isverified;

      if (checkVerifcationProcess) {
        let variables = {
          invite: this.invite,
          first: this.first,
          last: this.last,
          email: this.email,
          username: this.username,
          company_uri: this.company_uri,
          secret: this.secret,
          password: this.password,
          allow_occational_mail: this.occasionalMail,
          toc: this.agreeToPolicy,
          code: this.code === "" || this.code === null ? 10000 : this.code,
          id: this.restore.id,
        };

        try {
          const response = await this.$api.mutate({
            mutation: MemberRestoreMutation,
            variables,
          });

          if (!has(response, "error")) {
            this.loader.submit = false;

            this.$router.push({
              name: "SigninView",
            });
          }
        } catch (error) {
          this.serverValidation = errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidVerificationCodeError"
          );
          this.loader.submit = false;
        }
      } else {
        this.loader.submit = false;
      }
    },
    snackBarHandler(message) {
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message,
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    async singIn() {
      const variables = {
        uri: this.company_uri,
      };
      const response = await this.$api.query({
        query: CompanyQuery,
        variables,
      });

      this.company = response.data.company;
      if (!this.company) {
        this.errors.checkCompanyUri = this.$t(
          "message.validation.company.notExist"
        );
      } else {
        let credential = {
          username: this.email,
          password: this.password,
          company: this.company_uri,
        };
        const result = await login(credential);

        const user = await getUser(result.access_token);
        const { data } = await getWorkspaces(
          result.access_token,
          Workspaces2Query
        );
        let redirectWorkspaceId = null;
        const filteredWorkspaces = data.workspaces2.filter(
          (w) => !w.is_general && w.type !== "people" && w.type !== "personal"
        );
        if (filteredWorkspaces.length > 0) {
          redirectWorkspaceId = filteredWorkspaces[0].id;
        } else {
          redirectWorkspaceId = 1;
        }
        const shareData = {
          companyUri: this.company.uri,
          companyName: this.company.name,
          companyId: this.company.id,
          logo: this.company.logo,
          pricing_version: this.company.pricing_version,
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          walkthrough: user.last_session_at <= user.created_at,
          redirectWorkspaceId,
          status: this.company.status,
          isActive: this.company.status === "active",
        };
        if (user.status === "inactive") {
          this.errors.singIn =
            user.company.user_id === user.id
              ? this.$t("message.validation.ownerInactive")
              : this.$t("message.validation.memberInactive");
        } else {
          this.$store.dispatch("addToCompanies", shareData);
          this.$store.dispatch("activeCompany", this.company.uri);
          const checkCompany = await this.$api.mutate({
            mutation: CheckCompanyStatusMutation,
          });
          const status = {
            isActive:
              checkCompany.data.checkCompanyStatus.company_status === "active",
            subscriptionOver:
              checkCompany.data.checkCompanyStatus.subscription_over,
            graceHours: checkCompany.data.checkCompanyStatus.grace_hours,
            hasPaycard: checkCompany.data.checkCompanyStatus.hasPaycard,
          };
          this.$store.dispatch("companyStatus", status);
          this.handleLoginSuccess(shareData);
          let variables = {
            action: "login",
            user_id: user.id,
          };
          await this.$api.mutate({
            mutation: UserActionLogsMutation,
            variables,
          });
        }
      }
    },
    handleLoginSuccess(company) {
      if (company) {
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: this.$t("message.company.login"),
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        if (company.isActive) {
          this.$router.push({
            name: "BoardView",
            params: {
              company: company.companyUri,
              workspace: company.redirectWorkspaceId,
            },
          });
        } else {
          this.$router.push(`/companies/${company.companyUri}/payment`);
        }
      }
    },
  },
  computed: {
    isDisabled() {
      const checkVerifcationProcess = this.invite
        ? false
        : this.errorFlag.isverified || !this.isValidCode;
      if (
        this.$v.$invalid ||
        this.password.length < 8 ||
        this.errorFlag.usernameAvaibility ||
        this.errorFlag.emailAvaibility ||
        checkVerifcationProcess ||
        this.agreeToPolicy === false
      ) {
        return true;
      }
      return false;
    },
    isAvailabityError() {
      if (this.errorMessage.emailAvaibility && !this.loader.emailAvaibility) {
        return true;
      } else if (
        this.errorMessage.usernameAvaibility &&
        !this.loader.usernameAvaibility
      ) {
        return true;
      } else if (
        !this.invite &&
        this.errorMessage.isverified &&
        !this.loader.isverified
      ) {
        return true;
      } else {
        return false;
      }
    },
    availabityErrorMessage() {
      return this.errorFlag.emailAvaibility
        ? this.errorMessage.emailAvaibility
        : this.errorMessage.usernameAvaibility;
    },
    agreeToPolicy() {
      return this.privacyCheck && this.termsCheck;
    },
  },
};
</script>
<style lang="scss" scoped>
.masked-box {
  height: 50px;
  font-size: 3em;
  font-family: "Avenir Next";
  max-width: 250px;
  border: 1.5px solid #bfcfe0;
  border-radius: 6px;
  padding: 8px 0px 8px 10px;
  letter-spacing: 18px;
  background: #fff;
}
</style>
